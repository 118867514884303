<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn
          v-show="Vfibra100"
          class="botonFibra botonRedondeado"
          color="primary"
          block
        >
          FIBRA 100Mb
        </v-btn>
        <v-btn
          @click="fibra100()"
          v-show="!Vfibra100"
          block
          class="botonFibra botonRedondeado"
          outlined
          color="primary"
        >
          FIBRA 100Mb
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          v-show="Vfibra1000"
          class="botonFibra botonRedondeado"
          color="primary"
          block
        >
          FIBRA 1000Mb
        </v-btn>
        <v-btn
          class="botonFibra botonRedondeado"
          @click="fibra1000()"
          v-show="!Vfibra1000"
          block
          outlined
          color="primary"
        >
          FIBRA 1000Mb
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="9">
        <div class="fibra-titulo-fijo">Línea fija</div>
        <div class="fibra-titulo-lineas">
          Llamadas ilimitadas a fijos nacionales +100 min a móvil.
        </div>
      </v-col>
      <v-col cols="2" class="linea_swich">
        <v-switch v-model="opcionswitch" inset></v-switch>
      </v-col>
    </v-row>
    <v-list-item-content class="list-group">
      <v-row v-show="opcionswitch">
        <v-col class="botonLineaFijaDatosSeparacion">
          <v-chip
            class="botonChip"
            color="primary"
            v-show="tipo_alta_fijo == 'nueva linea'"
          >
            Alta
          </v-chip>
          <v-chip
            class="botonChip"
            v-show="tipo_alta_fijo != 'nueva linea'"
            @click="pasarAlineaNueva()"
          >
            Alta
          </v-chip>
          <v-chip
            class="botonChip"
            v-show="tipo_alta_fijo == 'portabilidad'"
            color="primary"
          >
            Portabilidad
          </v-chip>
          <v-chip
            class="botonChip"
            v-show="tipo_alta_fijo != 'portabilidad'"
            @click="pasarAPortabilidad()"
          >
            Portabilidad
          </v-chip>
        </v-col>

        <v-col>
          <v-text-field
            class="textoLineaFijoDatosPorta"
            v-show="tipo_alta_fijo == 'portabilidad'"
            label="Número móvil a portar"
            :rules="rulesMovil"
            @input="compruebaValidez"
            v-model="numeroPortar"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-container>
</template>

<script>
export default {
    data () {
        return {
            opcionswitch: false,
            Vfibra1000: true,
            Vfibra100: false,
            tipo_alta_fijo: '',
            numeroPortar: '',
            rulesMovil: [
                (v) =>
                    /^\+?[8-9]{1}-?[1-9]{1}-?[0-9]{7}$/.test(v) ||
          'Inserta un número válido'
            ]
        }
    },
    computed: {
        Vswitch () {
            return this.opcionswitch
        }
    },
    watch: {
        Vswitch (value) {
            if (value) {
                this.tipo_alta_fijo = 'nueva linea'
                var fijo = {
                    tipo_alta_fijo: 'nueva linea',
                    descripcion: 'FibraFijo ilimitadas + 100 min a móvil',
                    codigo_articulo: 'Tfijo',
                    tipo: 'Fijo',
                    unidades: '1',
                    precio: 6.0,
                    importe: 6.0
                }
                this.compruebaValidez()

                this.$store.dispatch('setswitchFijo', fijo)
            } else {
                this.tipo_alta_fijo = ''
                this.numeroPortar = ''

                this.$store.dispatch('setswitchFijo', value)
            }
        }
    },
    methods: {
        pasarAPortabilidad () {
            this.tipo_alta_fijo = 'portabilidad'
            this.numeroPortar = ''
            var fijo = {
                tipo_alta_fijo: 'portabilidad',
                descripcion: 'FibraFijo ilimitadas + 100 min a móvil',
                codigo_articulo: 'Tfijo',
                numeroPortar: '',
                tipo: 'Fijo',
                unidades: '1',
                precio: 6.0,
                importe: 6.0
            }
            this.$store.dispatch('setdatosPortabilidadesValidadosFijo', false)

            this.$store.dispatch('setswitchFijo', fijo)
        },
        pasarAlineaNueva () {
            this.tipo_alta_fijo = 'nueva linea'
            this.numeroPortar = ''
            var fijo = {
                tipo_alta_fijo: 'nueva linea',
                descripcion: 'FibraFijo ilimitadas + 100 min a móvil',
                codigo_articulo: 'Tfijo',
                tipo: 'Fijo',
                unidades: '1',
                numeroPortar: '',
                precio: 6.0,
                importe: 6.0
            }
            this.$store.dispatch('setdatosPortabilidadesValidadosFijo', true)

            this.$store.dispatch('setswitchFijo', fijo)
        },
        fibra100 () {
            const fibra = {
                codigo_articulo: 'FG100',
                color: 'primary',
                tipo: 'fibra',
                descripcion: 'Fibra 100Mb',
                unidades: '1',
                apartment_id: this.$store.getters.getcodigoCoberturaFibra,
                precio: 21.9,
                importe: 21.9
            }
            this.Vfibra100 = true
            this.Vfibra1000 = false

            this.$store.commit('SET_FIBRA', fibra)
        },
        fibra1000 () {
            const fibra = {
                codigo_articulo: 'FG1000',
                color: 'primary',
                tipo: 'fibra',
                descripcion: 'Fibra  1000Mb',
                unidades: '1',
                apartment_id: this.$store.getters.getcodigoCoberturaFibra,
                precio: 32.9,
                importe: 32.9
            }
            this.Vfibra100 = false
            this.Vfibra1000 = true
            this.$store.commit('SET_FIBRA', fibra)
        },
        compruebaValidez () {
            const patternMovil1 = /^\+?[8-9]{1}-?[1-9]{1}-?[0-9]{7}$/
            let validadorParametros = ''

            if (this.tipo_alta_fijo === 'portabilidad') {
                if (patternMovil1.test(this.numeroPortar)) {
                } else {
                    this.$store.dispatch('setdatosPortabilidadesValidadosFijo', false)
                    validadorParametros = false
                }
            }

            if (validadorParametros === '') {
                this.$store.dispatch('setdatosPortabilidadesValidadosFijo', true)
                var fijo = {
                    tipo_alta_fijo: 'portabilidad',
                    descripcion: 'FibraFijo ilimitadas + 100 min a móvil',
                    codigo_articulo: 'Tfijo',
                    tipo: 'Fijo',
                    unidades: '1',
                    numeroPortar: this.numeroPortar,
                    precio: 6.0,
                    importe: 6.0
                }
                this.$store.dispatch('setswitchFijo', fijo)
            }
        }
    },
    created () {
        if (!this.$store.getters.getfibraPreseleccionada) {
            this.Vfibra100 = false
            this.Vfibra1000 = true
        } else {
            this.Vfibra100 = true
            this.Vfibra1000 = false
        }
    }
}
</script>
